:root {
    --sys-color-light-on-surface: #434d56;

    --sys-color-light-surface-container: #eef0f2;
    --sys-color-light-surface-container-rgb: 238, 240, 242;

    --sys-color-light-surface-container-low: #f4f5f6;

    --sys-color-light-outline-variant: #c6cdd2;

    --sys-color-light-blue: #2589da;

    --sys-state-layer-light-surface-enabled: #e2e6e9;
    --sys-state-layer-light-surface-hovered: #c6cdd2;
    --sys-state-layer-light-surface-pressed: #a9b3bc;

    --sys-text-title-small-size: 16px;

    --sys-text-body-large-size: 16px;
    --sys-text-body-medium-size: 15px;
    --sys-text-body-small-size: 14px;
}
